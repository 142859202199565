.cea-content-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

// -------- button 'back to prev page' --------------
.prev-page-container {
  width: fit-content;
  margin-top: 0;
  margin-left: 0;
}

button.prev-page-link {
  height: 24px;
  width: auto;
  display: flex;
  gap: 8px;
  text-decoration: underline;
  align-items: center;
  text-underline-offset: 2px;
  color: rgba(44, 46, 74, 0.72);
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
// -------- button 'back to prev page' --------------

.cea-general-container {
  width: 100%;
  height: 93%;
  padding: 40px 80px 56px 80px;
  margin: 0 0 15px 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
}

.cea-general-container-master {
  width: 100%;
  height: 90%;
  padding: 40px 80px 56px 80px;
  margin: 0 0 15px 0;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
}

.create-account-btn-container,
.save-account-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0 0 0;
}

.ca-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 30px;
}

.ca-content-master {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title-disable-reactivate {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #0e0333;
}

.container-options-admin {
  display: flex;
  width: 100%;
  gap: 73px;
  cursor: default;
}

.disable {
  width: 30%;
}

.disable-delete-options-container-admin {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 16px 12px;
  background-color: #f2f6ff;
  border-radius: 8px;
  height: 170px;
  margin-top: 16px;
}

.ea-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(44, 46, 74, 0.64);
}

.connect-edit-btn {
  width: 100px;
  min-width: 0;
  display: flex;
  margin-top: 10px;
  gap: 6px;
}

.btn-status-disabled {
  position: relative;
  width: 42px;
  height: 42px;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  background: url('../../../images/menu-icons/deactivate-account-default.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.btn-status-active {
  position: relative;
  width: 42px;
  height: 42px;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  background: url('../../../images/menu-icons/activate-account-default.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.btn-status-delete {
  position: relative;
  width: 42px;
  height: 42px;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  background: url('../../../images/menu-icons/delete-account-default.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.input-disable {
  color: rgba(44, 46, 74, 0.64);
}

.valid-input {
  border-bottom: 2px solid #0e0333;
}

.field-container:focus-within .create-tour-input-labels {
  color: #0038ff;
}

.create-tour-input-labels-disable {
  color: #818aa6;
  font-weight: 400;
  margin: 20px 0 10px 0;
}

.field-show-password-icon {
  position: absolute;
  left: 100%;
  top: 96%;
  transform: translate(-150%, -125%);
  width: fit-content;
  height: 28px;
  border: none;
  background: none;
  cursor: pointer;
}

.field-container {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.btn-save {
  height: 42px;
  border-radius: 8px;
  color: white;
  min-width: 170px;
  background-color: #0e0333;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  cursor: pointer;
  padding: 0 12px 0 12px;
}

.btn-save-invalid {
  width: 222px;
  height: 52px;
  background: #c7ccda;
  color: #3F4770;
  font-size: 18px;
  border-radius: 8px;
  border: none;
  pointer-events: none;
}

.btn-create-invalid {
  width: 173px;
  height: 42px;
  background: #c7ccda;
  color: #818aa6;
  font-size: 18px;
  border-radius: 8px;
  border: none;
  pointer-events: none;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  padding: 0 12px 0 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

.btn-create {
  height: 42px;
  border-radius: 8px;
  color: white;
  min-width: 170px;
  background-color: #0e0333;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  cursor: pointer;
  padding: 0 12px 0 12px;
}

.input-wrong {
  border-bottom: 2px solid red;
}

.inputs-container {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  gap: 48px;
}

.inputs-container-master {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  gap: 48px;
  margin-bottom: 48px;
}

.container-general {
  display: flex;
  flex-direction: column;
}

.input-container-row {
  display: flex;
  width: 100%;
  gap: 73px;
}

.select-input {
  z-index: 1;
}

// -------------- pasword requirements ------------------------
.cea-pwd-should-contain-section {
  z-index: 2;
  position: absolute;
  top: 120%;
  width: auto;
  height: auto;
  padding: 21px 35px;
  font-size: 14px;
  border: 2px solid #7f8191;
  border-radius: 8px;
  background: #f7f7f8;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cea-pwd-should-contain-section:after,
.cea-pwd-should-contain-section:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.cea-pwd-should-contain-section:after {
  border-color: rgba(247, 247, 248, 0);
  border-bottom-color: #f7f7f8;
  border-width: 15px;
  margin-left: -15px;
}

.cea-pwd-should-contain-section:before {
  border-color: rgba(127, 129, 145, 0);
  border-bottom-color: #7f8191;
  border-width: 18px;
  margin-left: -18px;
}

.cea-pwd-should-contain-title {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.cea-pwd-should-contain-items-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cea-pwd-should-contain-item {
  display: flex;
  align-items: center;
  gap: 16px;
}

.cea-pwd-check-status {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.cea-pwd-check-text {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
// -------------- pasword requirements ------------------------
.master-input {
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  .rbt-input-main {
    height: 52px;
    width: 100%;
    padding: 16px 48px 16px 16px;
    font-size: 16px;
    background: #f2f6ff;
    border: none;
    border-bottom: 2px solid #0e0333;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.master-input-disable {
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  pointer-events: none;

  .rbt-input-main {
    height: 52px;
    width: 100%;
    padding: 16px 48px 16px 16px;
    font-size: 16px;
    background: #f2f3f5;
    color: #818aa6;
    border: none;
    border-bottom: 2px solid #818aa6;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.drag-and-drop-logo-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 36px;
  position: relative;
}

.container-text-logo {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title-logo {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  color: #0e0333;
}

.subtext-logo {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  color: #0e0333;
}

.drag-drop-text-container-circle {
  width: 150px;
  height: 150px;
  background-color: #f2f6ff;
  border: 1px solid #d5d7df;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.branding-input-settings-logo {
  position: absolute;
  cursor: pointer;
  width: 150px;
  height: 150px;
  color: transparent;
  border-radius: 50%;
  opacity: 0;
}

.logo-image {
  height: 150px;
  width: 150px;
}

.container-image-input {
  display: flex;
  gap: 48px;
  margin-bottom: 90px;
}


// ----------- countries dropdown ----------------
.cea-countries-container {
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  .rbt-input-main {
    height: 52px;
    cursor: pointer;
    width: 100%;
    padding: 16px 48px 16px 16px;
    font-size: 16px;
    background: #f2f6ff;
    border: none;
    border-bottom: 2px solid #0e0333;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .rbt-menu {
    border-radius: 4px;
    background-color: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    height: 180px;
  }

  .dropdown-menu {
    display: flex !important;
    flex-direction: column;
  }

  .dropdown-item {
    padding: 1rem 1rem 1rem 1rem;
    text-decoration: none;
    color: #8b8d9b;
  }

  .dropdown-item:hover {
    color: #2c2f48;
    background-color: #f3f5f9;
  }

  .cea-arrow-country-dropdown {
    position: absolute;
    right: 16px;
    bottom: 20px;
    pointer-events: none;
    cursor: pointer;
  }
}

// ----------- countries dropdown ----------------

// rules outside of @media queries apply to devices 1600px and above
// rules here apply to devices from 1024px to 1600px (inclusive)
@media only screen and (max-width: 1600px) {
  // -------------- pasword requirements ------------------------
  .cea-pwd-should-contain-section {
    padding: 21px 25px;
    gap: 18px;
  }

  .cea-pwd-should-contain-title {
    font-size: 13px;
  }

  .cea-pwd-check-status {
    width: 22px;
    height: 22px;
  }

  .cea-pwd-check-text {
    font-size: 13px;
  }
  // -------------- pasword requirements ------------------------
}

// rules here apply to devices from 0px to 1024px (inclusive)
@media only screen and (max-width: 1024px) {
  .btn-save {
    width: 212px;
    height: 42px;
    font-size: 15px;
  }

  .btn-save-invalid {
    width: 212px;
    height: 42px;
    font-size: 15px;
  }

  // -------------- pasword requirements ------------------------
  .cea-pwd-should-contain-section {
    padding: 21px 15px;
    gap: 16px;
  }

  .cea-pwd-should-contain-title {
    font-size: 12px;
  }

  .cea-pwd-check-status {
    width: 20px;
    height: 20px;
  }

  .cea-pwd-check-text {
    font-size: 12px;
  }
  // -------------- pasword requirements ------------------------
}

// tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}
